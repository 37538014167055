import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import "./ContactForm.css";
import emailjs from "emailjs-com";

const ContactForm = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: "",
    });

    const [status, setStatus] = useState(null);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // EmailJS template sending
        emailjs
            .sendForm(
                "service_439ck3u", // Replace with your service ID
                "template_0sz9ibw", // Replace with your template ID
                e.target, // Send the form data
                "Pp67OI_otvWhxVJV2" // this is the public key         
            )
            .then(
                (result) => {
                    console.log(result.text);
                    setStatus("Your message has been sent successfully!");

                    // Clear form data after successful submission
                    setFormData({
                        name: "",
                        email: "",
                        message: "",
                    });
                },
                (error) => {
                    console.log(error.text);
                    setStatus("Error sending message.");
                }
            );
    };

    return (
        <div className="contact-container">
            <h2>Contact</h2>
            <p>Reach out for special project requests.</p>

            {status && <p className="form-status">{status}</p>} {/* Display status message */}

            <form onSubmit={handleSubmit} className="contact-form">
                <div className="form-group">
                    <label>Name</label>
                    <input
                        type="text"
                        name="name"
                        required
                        value={formData.name}
                        onChange={handleChange}
                        placeholder="Your Name"
                    />
                </div>

                <div className="form-group">
                    <label>Email</label>
                    <input
                        type="email"
                        name="email"
                        required
                        value={formData.email}
                        onChange={handleChange}
                        placeholder="Your Email"
                    />
                </div>

                <div className="form-group">
                    <label>Message</label>
                    <textarea
                        name="message"
                        required
                        value={formData.message}
                        onChange={handleChange}
                        placeholder="Write your message..."
                    />
                </div>

                <button type="submit">Send Message</button>
            </form>
        </div>

    );
};

export default ContactForm;
