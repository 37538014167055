import React from 'react';
import GoogleMapComponent from './GoogleMap';
import './../Location/Location.css';

const Location = () => {
    return (
        <div className="location-page">
            <div className="map-wrapper">
                <GoogleMapComponent />
            </div>
            <div className="ghost-text">
                <span>SONOMA</span>
                <span>NAPA</span>
                <span>MARIN</span>
                <span>MENDO</span>
            </div>


        </div>
    );
};

export default Location; 