import React, { useState } from 'react';
import Hero from './../Hero/Hero';
import RelocationPopup from '../RelocationPopUP/RelocationPopup';
import Gallery from '../Gallery/Gallery';
import MoreGalleries from '../MoreGalleries/MoreGalleries';
import Footer from '../Footer/Footer';
import './LandingPage.css';

const LandingPage = () => {
    const [galleryData, setGalleryData] = useState([]); // ← NEW STATE

    return (
        <div id="top" className="landing-page">
            <Hero />
            <RelocationPopup />
            <MoreGalleries setGalleryData={setGalleryData} /> {/* ← FETCH THE DATA */}
            <Gallery galleryData={galleryData} /> {/* ← PASS IT IN */}
            <Footer />
        </div>
    );
};

export default LandingPage;
