import React, { useEffect, useRef } from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import '../Location/Location.css';

const containerStyle = {
  width: "100%",
  height: "100%",
};

const mapCenter = {
  lat: 38.4331,
  lng: -122.7163,
};

const markerPosition = {
  lat: 38.44047,
  lng: -122.71443,
};

const mapOptions = {
  styles: [
    {
      elementType: "geometry",
      stylers: [{ color: "#f5f0e6" }],
    },
    {
      elementType: "labels.text.fill",
      stylers: [{ color: "#7c6f62" }],
    },
    {
      elementType: "labels.text.stroke",
      stylers: [{ color: "#ffffff" }],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [{ color: "#faeddb" }],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [{ color: "#dce3e7" }],
    },
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [{ color: "#e9e2d0" }],
    },
  ],
  disableDefaultUI: true,
  zoomControl: true,
};

const GoogleMapComponent = () => {
  const mapRef = useRef(null);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ["marker"],
  });

  useEffect(() => {
    if (
      isLoaded &&
      mapRef.current &&
      window.google?.maps?.marker?.AdvancedMarkerElement
    ) {
      const { AdvancedMarkerElement } = window.google.maps.marker;

      const markerEl = document.createElement("div");
      markerEl.style.backgroundImage = "url('/assets/chsl_mllt_vect.png')";
      markerEl.style.backgroundSize = "contain";
      markerEl.style.backgroundRepeat = "no-repeat";
      markerEl.style.width = "50px";
      markerEl.style.height = "50px";

      new AdvancedMarkerElement({
        map: mapRef.current,
        position: markerPosition,
        content: markerEl,
      });
    }
  }, [isLoaded]);

  if (!isLoaded) return <div>Loading Map...</div>;

  return (
    <div className="map-frame">
      <div className="map-overlay-container">
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={mapCenter}
          zoom={9}
          options={mapOptions}
          onLoad={(map) => (mapRef.current = map)}
        />
        <img
          src="/assets/chsl_mllt_vect.png"
          alt="Custom Marker"
          className="custom-overlay-marker"
        />
      </div>
    </div>
  );
  
};

export default GoogleMapComponent;