import React, { useEffect, useState } from 'react';
import axios from 'axios';

const MoreGalleries = ({ setGalleryData }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchGalleries = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/galleries`);
                console.log("Fetched Data:", response.data); // Debugging step
                setGalleryData(response.data);
            } catch (err) {
                console.error('Error fetching gallery:', err);
                setError('Failed to load gallery images. Please try again later.');
            } finally {
                setLoading(false);
            }
        };
        fetchGalleries();
    }, []);

    if (loading) return <div>Loading gallery...</div>;
    if (error) return <div>{error}</div>;

    return null; // Doesn't render anything, just passes data up
};

export default MoreGalleries;
