import React, { useState, useEffect } from 'react';
import './About.css';

const About = () => {
  // State to track if the screen width is above or below 1000px
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 1000);

  // Effect to update the screen size on window resize
  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 1000);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="about-container">
      <div className="background-overlay">
        <div className="about-words-container">
          {isWideScreen && (
            <div className="portrait-container">
              <img
                src="./assets/portrait_paul.jpg"
                alt="Paul's Portrait"
                className="portrait"
              />
            </div>
          )}
          <div className="text-container">
            <p>
              With over 20 years in the wood flooring industry, Paul has built a reputation for his skill and dedication. Since 1999, he's been transforming homes and businesses with floor installations, sanding, refinishing, and restoration work.
            </p>

            <p>
              Paul's background as a visual artist enhances his craft, giving him a unique eye for detail. In 2017, he launched his own flooring business, specializing in custom inlays, intricate borders, and restoring floors over 100 years old.
            </p>

            <p>
              Paul is skilled in floating floor systems, general construction, and project management, but his true passion lies in working with wood—whether it’s custom designs, restoration, or flawless installations.
            </p>

            <p>
              No matter the project, Paul’s dedication ensures that clients are always satisfied with the final result. His motto: "If it's a lifetime floor, it'll last as long as the house."
            </p>
          </div>
          {!isWideScreen && (
            <div className="portrait-container">
              <img
                src="./assets/portrait_paul.jpg"
                alt="Paul's Portrait"
                className="portrait"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default About;
