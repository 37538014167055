import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <footer className="landing-footer footer-container">
            <img src='./assets/wood_floor_PAWF_2.jpg' className="footer-image" alt="Wood Flooring Footer" />
            <div className="footer-overlay"></div>
            <p className="back-to-top">
                <a href="#top">BACK TO TOP</a>
            </p>
            <p className="footer-text">
            <a href="https://www.aalsop.com" target="_blank" rel="noopener noreferrer">
                <span className="website-text">Website by </span>
                <span className="ronnie-name">
                    <span className="ronnie-first-letter">R</span>onnie
                </span>
                </a>
            </p>
        </footer>
    );
};

export default Footer;
