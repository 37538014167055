import React, { useState, useRef } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LandingPage from "./components/LandingPage/LandingPage";
import Header from "./components/Header/Header";
import Contact from "./components/Contact/Contact";
import About from "./components/About/About";
import MoreGalleries from "./components/MoreGalleries/MoreGalleries";
import Location from "./components/Location/Location";


const App = () => {
    const [galleryData, setGalleryData] = useState([]);
    const mapSectionRef = useRef(null);

    return (
        <Router>
            <MoreGalleries setGalleryData={setGalleryData} /> {/* Fetches data on app load */}
            <Header apSectionRef={mapSectionRef} />
            <Header />
            <Routes>
                <Route path="/" element={<LandingPage galleryData={galleryData} />} />
                <Route path="/" element={<LandingPage mapSectionRef={mapSectionRef} />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/about" element={<About />} />
                <Route path="/location" element={<Location />} />
            </Routes>
        </Router>
    );
};

export default App;
