import React from "react";
import "./Contact.css"; // Background image styling
import ContactForm from "./ContactForm";


const Contact = () => {
    return (
        <div className="contact-page">
            
            <div className="background-overlay">
                <ContactForm />
            </div>
        </div>
    );
};

export default Contact;
